import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Table, TableBody,CircularProgress, TableCell, TableContainer, TableHead, Tabs, Tab, TableRow, Paper, Checkbox, Grid, Typography, DialogActions, Button, Modal, Dialog, DialogContent, DialogTitle, IconButton, Menu, Box, Select, FormControl, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommentIcon from '@mui/icons-material/Comment';
import ComputerIcon from '@mui/icons-material/Computer';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import MemoryIcon from '@mui/icons-material/Memory';
import StorageIcon from '@mui/icons-material/Storage';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import DevicesIcon from '@mui/icons-material/Devices';
import SdCardIcon from '@mui/icons-material/SdCard';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import RequestModal from './RequestModal';
import Tooltip from '@mui/material/Tooltip';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AppsIcon from '@mui/icons-material/Apps';
import AgentModal from './AgentModal';

const RootContainer = styled('div')({
    padding: '24px',
    position: 'relative',
});

const TableWrapper = styled(TableContainer)({
    marginBottom: '24px',
});

const AddTenantButton = styled(Button)(({ theme }) => ({
    position: 'absolute',
    top: '24px',
    right: '24px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));

const TenantButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));

const DataTable1 = () => {
    const [selectedTenant, setSelectedTenant] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [tenants, setTenants] = useState([]);
    const [open, setOpen] = useState(false);
    const [openAgent, setAgentOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredTenants, setFilteredTenants] = useState([]);
    const [systemConfigOpen, setSystemConfigOpen] = useState(false);
    const [selectedSystemConfig, setSelectedSystemConfig] = useState(null);
    const [assignDialogOpen, setAssignDialogOpen] = useState(false);
    const [updateStatusDialogOpen, setUpdateStatusDialogOpen] = useState(false);
    const [tenantCodes, setTenantCodes] = useState([]);
    const [selectedSystems, setSelectedSystems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [actionDialogOpen, setActionDialogOpen] = useState(false);
    const [currentSystem, setCurrentSystem] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedDropdownTenant, setSelectedDropdownTenant] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [tenantName, setTenantName] = useState('');
    const [tenantCompanyName, setTenantCompanyName] = useState('');
    const [tenantCode, setTenantCode] = useState('');
    const [selectedTenantCode, setSelectedTenantCode] = useState('');
    const [currentTenantCode, setCurrentTenantCode] = useState('');
    const [formattedData, setFormattedData] = useState({});
    const [acceptedChanges, setAcceptedChanges] = useState(false);
    const [asset_id, setAssetId] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [systemsData, setSystemsData] = useState([]);
    const [systemSearchQuery, setSystemSearchQuery] = useState('');
    const [tabIndex, setTabIndex] = useState(0);
    const [filteredSystems, setFilteredSystems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [systemStatus, setSystemStatus] = useState('active');
    const [openModal, setOpenModal] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [comments, setComments] = useState([]);
    const [selectedAssetId, setSelectedAssetId] = useState(null);
    const [logs, setLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [newComment, setNewComment] = useState("");
    const [filter, setFilter] = useState('All');
    const [tenantStatus, setTenantStatus] = useState("active"); // State for Tenants tab
    const [SelectedTenantStatus, setSelectedTenantStatus] = useState(""); // State for
    const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
    const [newAssetId, setNewAssetId] = useState('');
    const [hoverData, setHoverData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [modalVisible, setModalVisible] = useState(false);
    const isMenuOpen = Boolean(anchorEl);

    const [dashboardStats, setDashboardStats] = useState({
        system: {
            active_systems: 0,
            changed_systems: 0,
            inactive_systems: 0,
            shutdown_systems: 0,
            total_systems: 0
        },
        tenant: {
            active_tenants: 0,
            inactive_tenants: 0,
            total_tenants: 0
        }
    });

    const [agentInfo, setAgentInfo] = useState({
        name: '',
        mobileNumber: '',
        email: '',
        designation: '',
        password: '',
        agentId: '',
    });

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleCheckboxChange = (event, assetId) => {
        setSelectedSystems(prevSelectedSystems => {
            const newSelectedSystems = event.target.checked
                ? [...prevSelectedSystems, assetId]
                : prevSelectedSystems.filter(id => id !== assetId);

            const allAssetIds = selectedTenant.systems.map(system => system.asset_id);
            const isAllSelected = newSelectedSystems.length === allAssetIds.length;

            setSelectAll(isAllSelected);
            return newSelectedSystems;
        });
    };

    const handleSelectAllChange = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);
        if (selectedTenant && selectedTenant.systems) {
            if (checked) {
                setSelectedSystems(selectedTenant.systems.map(system => system.asset_id));
            } else {
                setSelectedSystems([]);
            }
        }
    };

    const handleActionClick = () => {
        console.log('Selected Asset IDs:', selectedSystems);
        setActionDialogOpen(true);
    };

    const fetchAllTenant = () => {
        const token = sessionStorage.getItem("token");
        axios.get('https://varnetbuddy.varnetsolutions.in/get_tenant_data', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.status === 401) {
                    window.location.href = '/login';
                    return;
                }
                const tenantData = response.data;
                const tenantsData = tenantData.map(item => ({
                    id: item.tenant_code,
                    tenant_name: item.tenant_name,
                    tenant_company_name: item.tenant_company_name,
                    tenant_code: item.tenant_code,
                    Count: item.total_count,
                    Active: item.active_count,
                    Inactive: item.inactive_count,
                    systems: [],
                }));

                setTenants(tenantsData);
                setFilteredTenants(tenantsData);
            })
            .catch(error => {
                console.error('Error fetching tenants:', error);
            });
    };

    const handleLogsClick = () => {
        navigate('/audit-logs');
        handleMenuClose();
    };

    const handleAddAgent = () => {
        // Add your logic for adding agent
        handleMenuClose();
    };
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const fetchTenantData = async (status) => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            window.location.href = '/login'
            return;
        }
        try {
            const response = await axios.get(`https://varnetbuddy.varnetsolutions.in/get_tenant_data/${status}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log(response)
            if (response.status === 401) {
                window.location.href = '/login'
                return;
            }
            const tenantData = response.data;

            const formattedData = tenantData.map(item => ({
                id: item.tenant_code,
                tenant_name: item.tenant_name,
                tenant_company_name: item.tenant_company_name,
                tenant_code: item.tenant_code,
                Count: item.total_count,
                Active: item.active_count || 0,
                Inactive: item.inactive_count || 0,
                systems: item.systems || [],
            }));
            setTenants(formattedData);
            setSelectedTenantStatus(status);

        } catch (error) {
            console.error('Error fetching tenant data:', error);
        }
    };

    const handleStatusChange = (e) => {
        const status = e.target.value;
        setSelectedStatus(status);
        fetchTenantData(status);
        setSystemStatus(status);
        fetchSystemsData(e.target.value);
    };

    useEffect(() => {
        fetchTenantData('active');
        fetchDashboardStats();
    }, []);

    const getSystemStatusCount = (tenant) => {
        if (!tenant || !Array.isArray(tenant.systems)) {
            return { activeCount: tenant.Active || 0, inactiveCount: tenant.Inactive || 0 };
        }

        const activeCount = tenant.systems.filter(system => system.status === 'active').length;
        const inactiveCount = tenant.systems.filter(system => system.status === 'inactive').length;

        return { activeCount, inactiveCount };
    };

    const handleTenantHover = async (tenantCode) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://varnetbuddy.varnetsolutions.in/api/GetNamesCountByTenantCode/${tenantCode}`);
            setHoverData(response.data);
        } catch (error) {
            console.error('Error fetching tenant data:', error);
        } finally {
            setLoading(false);
        }
    };

    const formatDataForTooltip = (data) => {
        if (!data || data.length === 0) return 'No data available';

        return (
            <div style={{ maxWidth: '400px', maxHeight: '400px', overflowY: 'auto', textAlign: 'left' }}>
                {data.map((item, index) => {
                    const [key, value] = Object.entries(item)[0];
                    return (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 10px', borderBottom: '1px solid #ddd' }}>
                            <span style={{ fontWeight: 'bold', color: '#ffffff', padding: '5px 5px' }}>{key}</span>
                            <span style={{ color: '#ffffff', fontWeight: 'bold', padding: '5px 5px' }}>{value}</span>
                        </div>
                    );
                })}
            </div>
        );
    };

    const fetchDashboardStats = async () => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            window.location.href = '/login'
            return;
        }

        try {
            const response = await fetch('https://varnetbuddy.varnetsolutions.in/dashboard_stats', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            setDashboardStats(data);
        } catch (error) {
            console.error('Error fetching dashboard stats:', error);
        }
    };

    const handleLogout = () => {
        setIsLoading(true);
        setTimeout(() => {
            sessionStorage.removeItem('token');
            navigate('/login');
        }, 2000);
    };

    useEffect(() => {
        if (selectedTenant && selectedTenant.systems) {
            const results = selectedTenant.systems.filter(system => {
                const macAddress = system.systemInfo?.Network?.MACAddress || '';
                const assetId = system.asset_id || '';
                return (
                    macAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    assetId.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });
            setFilteredSystems(results);
        } else {
            setFilteredSystems([]);
        }
    }, [selectedTenant, searchTerm]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleTenantStatusChange = (e) => {
        const status = e.target.value;
        setTenantStatus(status);
        fetchTenantData(status);
    };

    const handleSystemStatusChange = (e) => {
        const status = e.target.value;
        setSystemStatus(status);
        fetchSystemsData(status);
    };

    const handleAssetClick = (assetId) => {
        setSelectedAssetId(assetId);
        axios.get(`https://varnetbuddy.varnetsolutions.in/get_audit_logs/${assetId}`)
            .then(response => {
                const sortedLogs = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                setLogs(sortedLogs);
                setFilteredLogs(sortedLogs);
                setOpenDialog(true);
            })
            .catch(error => {
                console.error(`Error fetching logs for asset ${assetId}:`, error);
            });
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setLogs([]);
    };

    const handleFilterChange = (event) => {
        const selectedFilter = event.target.value;
        setFilter(selectedFilter);

        if (selectedFilter === 'All') {
            setFilteredLogs(logs);
        } else {
            const filtered = logs.filter(log => log.action === selectedFilter);
            setFilteredLogs(filtered);
        }
    };

    // useEffect(() => {
    //     const fetchSystemStats = async () => {
    //         const token = sessionStorage.getItem("token");

    //         try {
    //             const response = await fetch('http://31.220.89.68:1000/system_stats', {
    //                 headers: {
    //                     'Authorization': `Bearer ${token}`
    //                 }
    //             });
    //             const data = await response.json();
    //             setSystemStats(data);
    //         } catch (error) {
    //             console.error('Error fetching system stats:', error);
    //         }
    //     };

    //     fetchSystemStats();
    // }, []);

    const handleAgentSubmit = async (e) => {
        e.preventDefault();

        // Format data according to API requirements
        const formattedAgentInfo = {
            name: agentInfo.name,
            mobile_number: agentInfo.mobileNumber,
            email: agentInfo.email,
            designation: agentInfo.designation,
            password: agentInfo.password,
            agent_id: agentInfo.agentId,
        };

        const token = sessionStorage.getItem("token");
        if (!token) {
            console.error("No token found. Please log in.");
            return;
        }

        try {
            console.log('Sending request with:', formattedAgentInfo);
            const response = await fetch('https://varnetbuddy.varnetsolutions.in/register_agent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(formattedAgentInfo),
            });

            console.log('Response status:', response.status);
            console.log('Response body:', await response.text());

            if (response.ok) {
                console.log('Agent added successfully');
                // Clear the form
                setAgentInfo({
                    name: '',
                    mobileNumber: '',
                    email: '',
                    designation: '',
                    password: '',
                    agentId: '',
                });
                handleClose();
            } else {
                console.error('Failed to add agent:', response.statusText);
            }
        } catch (error) {
            console.error('Error adding agent:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAgentInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
    };

    const handleOpen = () => setAgentOpen(true);

    const handleOpenModal = (system) => {

        setCurrentSystem(system);
        fetchComments(system.asset_id); // Fetch comments when opening the modal
        setOpenModal(true);
    };
    const handlelicenseOpen = (tenant_code) => {
        console.log(tenant_code)
        setSelectedTenant(tenant_code)
        setModalVisible(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setNewComment(""); // Clear the new comment field when closing the modal
        setModalVisible(false);
    };

    useEffect(() => {
        if (currentSystem) {
            fetchComments(currentSystem.asset_id);
        }
    }, [currentSystem]);

    const fetchComments = async (assetId) => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            console.error("No token found. Please log in.");
            return;
        }

        try {
            const response = await fetch(
                `https://varnetbuddy.varnetsolutions.in/get_comments/${assetId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (response.ok) {
                const data = await response.json();
                const sortedComments = data.sort((a, b) => {
                    return new Date(b.timestamp) - new Date(a.timestamp);
                });

                setComments(sortedComments || []);
            } else {
                console.error("Failed to fetch comments:", response.statusText);
                setComments([]);
            }
        } catch (error) {
            console.error("Error fetching comments:", error);
            setComments([]);
        }
    };

    const handleAddComment = async () => {
        if (newComment.trim() === "") return;

        const token = sessionStorage.getItem("token");
        if (!token) {
            console.error("No token found. Please log in.");
            return;
        }

        try {
            const response = await fetch(
                `https://varnetbuddy.varnetsolutions.in/add_comment/${currentSystem.asset_id}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify({ comment: newComment }),
                }
            );

            if (response.ok) {
                setNewComment("");
                fetchComments(currentSystem.asset_id);
            } else {
                console.error("Failed to add comment:", response.statusText);
            }
        } catch (error) {
            console.error("Error adding comment:", error);
        }
    };

    const commentsContainerStyle = {
        maxHeight: "200px",
        overflowY: "auto",
        marginBottom: "16px",
    };

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        backgroundColor: "white",
        border: "2px solid #000",
        boxShadow: 24,
        padding: "16px 32px 24px",
    };

    const commentStyle = {
        padding: "8px",
        borderBottom: "1px solid #ccc",
    }

    const pillStyle = {
        display: 'inline-block',
        padding: '5px 10px',
        borderRadius: '20px',
        minWidth: '40px',
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#fff',

    };

    const activePill = {
        backgroundColor: 'green',
    };

    const inactivePill = {
        backgroundColor: 'red',
    };

    const shutdownPill = {
        backgroundColor: 'orange',
    };

    const changedPill = {
        backgroundColor: 'pink',
        color: '#333',
    };

    const totalPill = {
        backgroundColor: 'skyblue',
        color: '#fff'
    };

    const updateTenantStatus = async (tenantCode, newStatus) => {
        const token = sessionStorage.getItem("token");

        try {
            const apiUrl = newStatus === 'inactive'
                ? `https://varnetbuddy.varnetsolutions.in/tenant_status_update_to_inactive/${tenantCode}`
                : `https://varnetbuddy.varnetsolutions.in/tenant_status_update_to_active/${tenantCode}`;

            await axios.post(apiUrl, null, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            fetchTenantData(selectedStatus);
            handleMenuClose();
            toast.success(`Tenant status updated to ${newStatus}`);
        } catch (error) {
            console.error('Error updating tenant status:', error);
            toast.error('Failed to update tenant status');
        }
    };

    const fetchSystemForTenant = (tenant_code) => {
        const token = sessionStorage.getItem("token");

        axios.get(`https://varnetbuddy.varnetsolutions.in/api/ShowTenant/${tenant_code}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.status === 401) {
                    // Unauthorized, redirect to login
                    window.location.href = '/login';
                    return;
                }
                const system_info = response.data;
                const systems = system_info.map(system => ({
                    asset_id: system.asset_id,
                    name: system.name,
                    Name: system.asset_id,
                    locked: system.Lock === "True",
                    active: system.status === "active",
                    systemInfo: system.system_info,
                    tenant_code: system.tenant_code,
                    status: system.status,
                    macAddress: system.system_info.Network?.MACAddress || 'N/A',
                }));
                setSelectedTenant(prevTenant => ({
                    ...prevTenant,
                    tenant_code: tenant_code,
                    systems: systems,
                }));
                setCurrentTenantCode(prevTenant => (tenant_code));
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                let token = sessionStorage.getItem("token");
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ` + token);
                let requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                }
                const response = await fetch('https://varnetbuddy.varnetsolutions.in/api/all_system_in_dashboard', requestOptions);
                if (response.status === 401) {
                    window.location.href = '/login';
                    return;
                }

                const data = await response.json();

                if (Array.isArray(data)) {
                    setSystemsData(data);
                } else {
                    console.log('Data', data);
                }
            } catch (error) {
                console.error('Error fetching systems data:', error);
            }
        };
        fetchData();
    }, []);

    const fetchSystemsData = async (status, page = 1) => {
        const token = sessionStorage.getItem("token");
        let url = '';

        switch (status) {
            case 'active':
                url = `https://varnetbuddy.varnetsolutions.in/active_system_dashboard/${page}`;
                break;
            case 'inactive':
                url = `https://varnetbuddy.varnetsolutions.in/inactive_systems_dashboard/${page}`;
                break;
            case 'shutdown':
                url = `https://varnetbuddy.varnetsolutions.in/shutdown_systems_dashboard/${page}`;
                break;
            case 'changed':
                url = `https://varnetbuddy.varnetsolutions.in/changed_systems_dashboard/${page}`;
                break;
            default:
                return;
        }

        try {
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = response.data.items || [];
            setSystemsData(data);
            setFilteredSystems(data);
            setTotalPages(response.data.total_pages || 1);
            setSelectedStatus(status)
        } catch (error) {
            console.error('Error fetching systems data:', error);
            setSystemsData([]);
            setFilteredSystems([]);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            const newPage = currentPage + 1;
            setCurrentPage(newPage);
            fetchSystemsData(systemStatus, newPage);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            const newPage = currentPage - 1;
            setCurrentPage(newPage);
            fetchSystemsData(systemStatus, newPage);
        }
    };

    useEffect(() => {
        fetchSystemsData(systemStatus, currentPage);
    }, [systemStatus, currentPage]);

    useEffect(() => {
        setFilteredSystems(systemsData.filter(system =>
            system.asset_id.toLowerCase().includes(systemSearchQuery.toLowerCase()) ||
            system.tenant_code.toLowerCase().includes(systemSearchQuery.toLowerCase()) ||
            system.tenant_name.toLowerCase().includes(systemSearchQuery.toLowerCase())
        ));
    }, [systemsData, systemSearchQuery]);


    const handleSystemSearch = (event) => {
        setSystemSearchQuery(event.target.value);
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    useEffect(() => {
        if (tenants.length > 0) {
            const filtered = tenants.filter((tenant) => {
                return (
                    (tenant.tenant_name && tenant.tenant_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
                    (tenant.tenant_company_name && tenant.tenant_company_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
                    (tenant.tenant_code && tenant.tenant_code.includes(searchQuery))
                );
            });
            setFilteredTenants(filtered);
        }
    }, [tenants, searchQuery]);

    const handleTenantClick = (tenant_code) => {
        setSelectedTenant(tenant_code);
        setCurrentSystem(tenant_code);
        setOpen(true);
        fetchSystemForTenant(tenant_code);
        console.log('Tenant clicked:', tenantCode);

    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleFetchSystemConfig = async (asset_id, setOldData, setNewData, setChanges, setShowChangeDialog) => {
        const data = await fetchSystemConfigByAssetId(asset_id);
        if (data === null) {
            console.error('No history record found for the given asset_id');
            return;
        }
    };

    const handleClose = () => {
        setOpen(false);
        setAgentOpen(false);
        setSelectedSystems([]);
        setSelectedTenant(null);
        setAnchorEl(null);
        setCurrentSystem(null);
        setSearchTerm('');
    };

    const handleMenuClick = (event, system) => {
        setAnchorEl(event.currentTarget);
        setCurrentSystem(system);

    };
    const handleModalOpen = () => {
        setModalOpen(true);
        handleMenuClose();
    };

    const handleModalClose = () => {
        setModalOpen(false);

    };

    const handleRequestModalOpen = () => {
        setIsRequestModalOpen(true);
    };

    const handleRequestModalClose = () => {
        setIsRequestModalOpen(false);
        handleMenuClose();
    };

    const handleAddTenant = async () => {
        const token = sessionStorage.getItem("token");

        const tenantData = {
            tenant_name: tenantName,
            tenant_company_name: tenantCompanyName,
            tenant_code: tenantCode,
        };

        try {
            const response = await axios.post('https://varnetbuddy.varnetsolutions.in/AddTenant', tenantData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 401) {
                // Unauthorized, redirect to login
                window.location.href = '/login';
                return;
            }

            console.log('Tenant added successfully:', response.data);
            toast.success('Tenant Added')
        } catch (error) {
            console.error('Error adding tenant:', error);
            toast.error('Error in Adding Tenant')
        }

        handleModalClose();
        fetchTenantData('active');
        handleMenuClose();
        // fetchAllTenant();

    };

    const handleClick = (event, tenant_code) => {
        setAnchorEl(event.currentTarget);
        setSelectedTenantCode(tenant_code);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedTenantCode('');

    };

    const assignAction = () => {
    };

    const SystemConfigDialog = ({ open, onClose, data, asset_id }) => {
        const [formattedData, setFormattedData] = useState(null);
        const [acceptedChanges, setAcceptedChanges] = useState(false);

        useEffect(() => {
            if (data) {
                setFormattedData(data);
            }
        }, [data]);
    };

    const oldData = formattedData?.old_data || {};
    const newData = formattedData?.new_data || {};
    const changes = formattedData?.changes || {};

    const extractKeys = (data, prefix = '') => {
        return Object.keys(data).reduce((acc, key) => {
            const fullKey = prefix ? `${prefix}.${key}` : key;
            if (typeof data[key] === 'object' && data[key] !== null) {
                return [...acc, ...extractKeys(data[key], fullKey)];
            }
            return [...acc, fullKey];
        }, []);
    };

    const allKeys = Array.from(new Set([
        ...extractKeys(oldData),
        ...extractKeys(newData),
        ...extractKeys(changes)
    ]));


    const getValue = (data, key) => {
        const value = key.split('.').reduce((acc, part) => acc && acc[part], data);
        return typeof value === 'object' ? JSON.stringify(value) : value || 'N/A';
    };

    const fetchSystemConfigByAssetId = async (asset_id) => {
        const token = sessionStorage.getItem("token");

        try {
            const response = await fetch(`https://varnetbuddy.varnetsolutions.in/api/get_systems_data_by_Asset_id/${asset_id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 401) {
                window.location.href = '/login';
                return null;
            }
            if (response.status === 422) {
                window.location.href = '/login';
                return null;
            }

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch system configuration:', error);
            return null;
        }
    };

    const handleAcceptChanges = async () => {
        const token = sessionStorage.getItem("token");

        console.log(asset_id);
        try {
            const response = await fetch(`https://varnetbuddy.varnetsolutions.in/api/accept_changes/${asset_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(async (response) => {
                if (response.status === 401) {
                    window.location.href = '/login';
                    return;
                }
                if (response.status === 200) {
                    toast.success("Changes Accepted")
                    const acceptedChangesKeys = await response.json();
                    setAcceptedChanges(acceptedChangesKeys.reduce((acc, key) => ({ ...acc, [key]: true }), {}));
                }
                else {
                    toast.error("Failed Accepting changes")
                }
            }).catch((error) => {
                console.error('Failed to accept changes:', error);
            })


        } catch (error) {
            toast.error("Failed Accepting es")
            console.error('Failed to accept changes:', error);
        }
    };

    const getChangeColor = (key) => {
        return acceptedChanges[key] ? 'black' : (changes[key] ? 'red' : 'black');
    };

    const handleViewConfiguration = async (system) => {
        try {
            const configData = await fetchSystemConfigByAssetId(system.asset_id);
            if (configData) {
                setFormattedData(configData);
                setAssetId(system.asset_id);
                setSystemConfigOpen(true);

            } else {
                console.error('No data received from API');
            }
        } catch (error) {
            console.error('Failed to load system configuration', error);
        }
    };

    useEffect(() => {
        if (formattedData) {
            console.log('Formatted data updated:', formattedData);
        }
    }, [formattedData]);

    const handleConfigDialogClose = () => {
        setSystemConfigOpen(false);
        setSelectedSystemConfig(null);
    };

    const getActiveStatus = (system) => {

        console.log(system)
        switch (system) {
            case 'lock':
                return <div style={{ color: 'blue' }}>Locked</div>
            case 'Shutdown':
                return <div style={{ color: 'brown' }}>Shutdown</div>
            case 'inactive':
                return <div style={{ color: 'indigo' }}>Inactive</div>
            case 'active':
                return <div style={{ color: 'green' }}>Active</div>
            case 'changed':
                return <div style={{ color: 'red' }}>changed</div>
            default:
                return <div style={{ color: 'pink' }}>{system}</div>
        }
    };

    const handleDropdownChange = (event) => {
        setSelectedDropdownTenant(event.target.value);
    };

    const handleAssignClick = (system) => {
        const token = sessionStorage.getItem("token");

        setCurrentSystem(system);
        setAssignDialogOpen(true);

        axios.get('https://varnetbuddy.varnetsolutions.in/ShowTenant', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                const tenantCodesData = JSON.parse(response.data);
                console.log(tenantCodesData);
                setTenantCodes(tenantCodesData);
            })
            .catch(error => console.error('Error fetching tenant codes:', error));
    };

    const handleAssignDialogClose = () => {
        setAssignDialogOpen(false);
        setCurrentSystem(null);
        setSelectedTenantCode('');
    };

    const handleUpdateStatusDialogClose = () => {
        setUpdateStatusDialogOpen(false);
        setCurrentSystem(null);
        setSelectedTenantCode('');

    };
    const formatDataForDisplay = (data) => {
        if (!data || !data.old_data || !data.new_data || !data.changes) return [];

        const systemInfoKeys = Object.keys(data.old_data.system_info || {});
        return systemInfoKeys.map(key => ({
            key,
            oldDetails: data.old_data.system_info[key],
            newDetails: data.new_data.system_info[key],
            changes: data.changes.changes?.system_info[key] || {}
        }));
    };
    const dataToDisplay = formatDataForDisplay(formattedData);

    const handleAssign = (tenantCode) => {
        assignSystem(selectedSystems, tenantCode);
        handleAssignDialogClose();
    };

    const handleUpdateStatus = (status, t_code) => {
        updateAssetStatus(selectedSystems, status, t_code);
        setCurrentTenantCode(t_code);
        handleUpdateStatusDialogClose();
    };

    const handleActionClose = () => {
        setActionDialogOpen(false);
    };

    const handleActionSubmit = async (action) => {
        const apiBaseUrl = 'https://varnetbuddy.varnetsolutions.in/api';
        const assetIds = selectedSystems;
        const token = sessionStorage.getItem("token");

        try {
            for (let asset_id of assetIds) {
                switch (action) {
                    case 'shutdown':
                        console.log('Shutting down selected systems');
                        const shutdownResponse = await axios.get(`${apiBaseUrl}/shutdown_change_true/${asset_id}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        if (shutdownResponse.status === 401) {
                            // Unauthorized, redirect to login
                            window.location.href = '/login';
                            return;
                        }
                        if (shutdownResponse.status === 200) {
                            toast.success(`System ${asset_id} shut down successfully`);
                            fetchSystemForTenant(selectedTenant.tenant_code)
                            handleActionClose()
                        } else {
                            toast.error(`Failed to shut down system ${asset_id}`);
                        }
                        break;
                    case 'switchOn':
                        console.log('Switching on selected systems');
                        const switchOnResponse = await axios.get(`${apiBaseUrl}/shutdown_change_false/${asset_id}`, {
                            headers: {
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        if (switchOnResponse.status === 401) {
                            window.location.href = '/login';
                            return;
                        }
                        if (switchOnResponse.status === 200) {
                            toast.success(`System ${asset_id} switched on successfully`);
                            fetchSystemForTenant(selectedTenant.tenant_code)
                            handleActionClose()
                        } else {
                            toast.error(`Failed to switch on system ${asset_id}`);
                        }
                        break;
                    case 'assign':
                        setAssignDialogOpen(true);
                        break;
                    case 'updateStatus':
                        setUpdateStatusDialogOpen(true);
                        break;
                    default:
                        console.log('Unknown action');
                        toast.error('Unknown action');
                        break;
                }
            }
        } catch (error) {
            console.error('Error performing action:', error);
            toast.error('An error occurred while performing the action');
        }

        setActionDialogOpen(false);
        setSelectAll(false);
    };

    const assignSystem = (asset_id, tenant_code) => {
        const token = sessionStorage.getItem("token");
        let data = JSON.stringify({
            asset_id
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://varnetbuddy.varnetsolutions.in/api/Update_Tenant/${tenant_code}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // Add Authorization header
            },
            data: data
        };

        axios.request(config)
            .then((response) => {

                if (response.status === 200) {
                    toast.success(response.data.message)
                    setSelectedSystems([]);
                    setTimeout(() => {
                    }, 5000);
                }
                else {
                    toast.error(response.data.message)
                    setTimeout(() => {
                    }, 5000);
                }
                if (response.status === 401) {
                    window.location.href = '/login';
                    return;
                }
            })
            .catch((error) => {
                toast.error(error)
                setTimeout(() => {
                }, 5000);
                console.log(error);
            });
    };

    const updateAssetStatus = (asset_ids, new_status, t_code) => {
        const token = sessionStorage.getItem("token");
        let data = JSON.stringify({
            asset_id: asset_ids,
            status: new_status
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://varnetbuddy.varnetsolutions.in/api/update_asset_status`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` // Add Authorization header
            },
            data: data
        };

        console.log(config)
        axios.request(config)
            .then((response) => {
                if (response.status === 200) {
                    toast.success(response.data.message)
                    setSelectedSystems([]);
                    fetchSystemForTenant(t_code)
                    setTimeout(() => {
                    }, 5000);
                }
                else if (response.status === 401) {
                    window.location.href = '/login';
                    return;
                }
                else {
                    toast.error(response.data.message)
                    setTimeout(() => {
                    }, 5000);
                }
            })
            .catch((error) => {
                toast.error(error)
                setTimeout(() => {
                }, 5000);
                console.log(error);
            });
    };

    const handleGenerateLicense = () => {
        console.log("license for this: " + selectedTenant)
        const formattedDate = `${selectedDate.getDate().toString().padStart(2, '0')}/${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}/${selectedDate.getFullYear()}`;
        const payload = {
            tenant_code: selectedTenant,
            date: formattedDate,
        };

        axios.post('https://varnetbuddy.varnetsolutions.in/api/generate_license', payload)
            .then(response => {
                console.log('License generated successfully', response.data);
                handleCloseModal();
                handleMenuClose();
            })
            .catch(error => {
                console.error('Error generating license', error);
            });
    }

    return (
        <>
            <ToastContainer />
            <RootContainer style={{ backgroundColor: '#E6F7FF', color: '#666', padding: 0, minHeight: '100vh', height: 'auto' }}>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '5px' }}>
                        <Button
                            onClick={handleLogout}
                            variant="contained"
                            color="secondary"
                            disabled={isLoading}
                            sx={{ float: 'right' }}
                        >
                            {isLoading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                'Log Out'
                            )}
                        </Button>
                        {/* //////////////////////////////////////////////////////////////// */}
                        {/* <Box>
                            <IconButton onClick={handleMenuOpen}>
                                <AppsIcon />
                            </IconButton>

                            <Menu
                                anchorEl={anchorEl}
                                open={isMenuOpen}
                                onClose={handleMenuClose}
                                PaperProps={{
                                    style: {
                                        width: '300px',
                                    },
                                }}
                            >
                                <MenuItem>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={handleRequestModalOpen}
                                        style={{ height: '40px' }}
                                    >
                                        Request
                                    </Button>
                                    <RequestModal open={isRequestModalOpen} onClose={handleRequestModalClose} assetId={newAssetId} />
                                </MenuItem>
                                <MenuItem>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={handleModalOpen}
                                        style={{ height: '40px' }}
                                    >
                                        Add Tenant
                                    </Button>
                                </MenuItem>
                                <MenuItem>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={handleOpen}
                                        style={{ height: '40px' }}
                                    >
                                        Add Agent
                                    </Button>
                                </MenuItem>
                                <MenuItem>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={handleLogsClick}
                                        style={{ height: '40px' }}
                                    >
                                        Logs
                                    </Button>
                                </MenuItem>
                                <div className="App" style={{overflow:'hidden'}}>
                                    <AgentModal />
                                </div>
                                <MenuItem>
                                    <Button
                                        onClick={handleLogout}
                                        variant="contained"
                                        color="secondary"
                                        disabled={isLoading}
                                        sx={{ float: 'right' }}
                                    >
                                        {isLoading ? (
                                            <CircularProgress size={24} color="inherit" />
                                        ) : (
                                            'Log Out'
                                        )}
                                    </Button>
                                </MenuItem>

                            </Menu>
                        </Box> */}
                        {/* //////////////////////////////////////////////////////////////// */}

                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#f5f5f5',
                        border: '1px solid #ccc',
                        padding: '10px',
                        borderRadius: '5px',
                        margin: '10px',
                        width: 'auto',
                        backgroundColor: '#E6F7FF'
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', marginRight: '20px', backgroundColor: '#E6F7FF' }}>
                        <Typography variant="h6" style={{ backgroundColor: 'skyblue' }}>
                            <strong>Tenant Stats</strong>
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                            <div style={{ marginRight: '20px', textAlign: 'center' }}>
                                <p style={{ fontWeight: 'bold' }}>Active Tenants</p>
                                <div style={{ ...pillStyle, ...activePill }}>{dashboardStats.tenant.active_tenants}</div>
                            </div>
                            <div style={{ marginRight: '20px', textAlign: 'center' }}>
                                <p style={{ fontWeight: 'bold' }}>Inactive Tenants</p>
                                <div style={{ ...pillStyle, ...inactivePill }}>{dashboardStats.tenant.inactive_tenants}</div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <p style={{ fontWeight: 'bold' }}>Total Tenants</p>
                                <div style={{ ...pillStyle, ...totalPill }}>{dashboardStats.tenant.total_tenants}</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', backgroundColor: '#E6F7FF' }}>
                        <Typography variant="h6" style={{ backgroundColor: 'skyblue' }}>
                            <strong>System Stats</strong>
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                            <div style={{ marginRight: '20px', textAlign: 'center' }}>
                                <p style={{ fontWeight: 'bold' }}>Active Systems</p>
                                <div style={{ ...pillStyle, ...activePill }}>{dashboardStats.system.active_systems}</div>
                            </div>
                            <div style={{ marginRight: '20px', textAlign: 'center' }}>
                                <p style={{ fontWeight: 'bold' }}>Changed Systems</p>
                                <div style={{ ...pillStyle, ...changedPill }}>{dashboardStats.system.changed_systems}</div>
                            </div>
                            <div style={{ marginRight: '20px', textAlign: 'center' }}>
                                <p style={{ fontWeight: 'bold' }}>Inactive Systems</p>
                                <div style={{ ...pillStyle, ...inactivePill }}>{dashboardStats.system.inactive_systems}</div>
                            </div>
                            <div style={{ marginRight: '20px', textAlign: 'center' }}>
                                <p style={{ fontWeight: 'bold' }}>Shutdown Systems</p>
                                <div style={{ ...pillStyle, ...shutdownPill }}>{dashboardStats.system.shutdown_systems}</div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <p style={{ fontWeight: 'bold' }}>Total Systems</p>
                                <div style={{ ...pillStyle, ...totalPill }}>{dashboardStats.system.total_systems}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ position: 'relative' }}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        aria-label="tabs"
                        style={{ paddingRight: '200px' }}
                    >
                        <Tab
                            label={
                                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                    <strong>Tenants</strong>
                                </Typography>
                            }
                        />
                        <Tab
                            label={
                                <Typography
                                    variant="h6"
                                    onClick={() => fetchSystemsData(selectedStatus)}
                                    style={{ fontWeight: 'bold' }}
                                >
                                    <strong>Assets</strong>
                                </Typography>
                            }
                        />
                    </Tabs>

                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px'
                        }}
                    >
                    <Button
                            variant="contained"
                            style={{ marginRight: '20px', height: '40px' }}
                            onClick={handleRequestModalOpen}
                        >
                            Request
                        </Button>
                        <RequestModal open={isRequestModalOpen} onClose={handleRequestModalClose} assetId={newAssetId} />

                        <Button
                            variant="contained"
                            onClick={handleModalOpen}
                            style={{ marginRight: '20px', height: '40px' }}
                        >
                            Add Tenant
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleOpen}
                            style={{ marginRight: '20px', height: '40px' }}
                        >
                            Add Agent
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleLogsClick}
                            style={{ height: '40px' }}
                        >
                            Logs
                        </Button>
                    </div>
                </div>

                <Grid container spacing={3}>
                    {tabIndex === 0 && (
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', gap: '50px' }}>
                                <FormControl size="small" style={{ width: '800px', marginBottom: '20px' }}>
                                    <Select
                                        labelId="tenant-status-label"
                                        value={tenantStatus}
                                        onChange={handleTenantStatusChange}
                                    >
                                        <MenuItem value="active"><Typography style={{ fontWeight: 'bold' }}>Active Tenants</Typography></MenuItem>
                                        <MenuItem value="inactive"><Typography style={{ fontWeight: 'bold' }}>Inactive Tenants</Typography></MenuItem>
                                    </Select>
                                </FormControl>

                                <div>
                                    <TextField
                                        size="small"
                                        type="search"
                                        variant="outlined"
                                        style={{ width: '800px', marginBottom: '20px' }}
                                        value={searchQuery}
                                        onChange={handleSearch}
                                        label="Search tenants"
                                    />
                                </div>
                            </div>

                            <TableWrapper component={Paper} style={{ backgroundColor: '#E6F7FF', color: '#666', fontWeight: 'bold' }} >


                                <Modal open={modalOpen} onClose={handleModalClose}>
                                    <Paper style={{ padding: 20, margin: '100px auto', maxWidth: 400 }}>
                                        <h2>Add Tenant</h2>
                                        <TextField
                                            label="Tenant Name"
                                            value={tenantName}
                                            onChange={(e) => setTenantName(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        />
                                        <TextField
                                            label="Tenant Company Name"
                                            value={tenantCompanyName}
                                            onChange={(e) => setTenantCompanyName(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        />
                                        <TextField
                                            label="Tenant Code"
                                            value={tenantCode}
                                            onChange={(e) => setTenantCode(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        />
                                        <Button variant="contained" color="primary" onClick={handleAddTenant}>
                                            Add
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleModalClose}
                                            style={{ margin: 10 }}
                                        >
                                            Cancel
                                        </Button>
                                    </Paper>
                                </Modal>

                                <Modal open={openAgent} onClose={handleClose}>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            width: 400,
                                            bgcolor: 'background.paper',
                                            borderRadius: '8px',
                                            boxShadow: 24,
                                            p: 4,
                                        }}
                                    >
                                        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                                            Add Agent
                                        </Typography>
                                        <form onSubmit={handleAgentSubmit}>
                                            <TextField
                                                label="Name"
                                                name="name"
                                                value={agentInfo.name}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                required
                                            />
                                            <TextField
                                                label="Mobile Number"
                                                name="mobileNumber"
                                                value={agentInfo.mobileNumber}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                required
                                            />
                                            <TextField
                                                label="Email"
                                                name="email"
                                                value={agentInfo.email}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                required
                                            />
                                            <TextField
                                                label="Designation"
                                                name="designation"
                                                value={agentInfo.designation}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                required
                                            />
                                            <TextField
                                                label="Password"
                                                name="password"
                                                type="password"
                                                value={agentInfo.password}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                required
                                            />
                                            <TextField
                                                label="Agent ID"
                                                name="agentId"
                                                value={agentInfo.agentId}
                                                onChange={handleChange}
                                                fullWidth
                                                margin="normal"
                                                required
                                            />
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                sx={{ mt: 2 }}
                                            >
                                                Submit
                                            </Button>
                                        </form>
                                    </Box>
                                </Modal>

                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><Typography style={{ fontWeight: 'bold' }}>Tenant Code</Typography></TableCell>
                                            <TableCell><Typography style={{ fontWeight: 'bold' }}>Tenant Name</Typography></TableCell>
                                            <TableCell><Typography style={{ fontWeight: 'bold' }}>Company Name</Typography></TableCell>
                                            <TableCell><Typography style={{ fontWeight: 'bold' }}>Systems</Typography></TableCell>
                                            <TableCell><Typography style={{ fontWeight: 'bold' }}>Total Systems</Typography></TableCell>
                                            <TableCell><Typography style={{ fontWeight: 'bold' }}>Active</Typography></TableCell>
                                            <TableCell><Typography style={{ fontWeight: 'bold' }}>Inactive</Typography></TableCell>
                                            <TableCell><Typography style={{ fontWeight: 'bold' }}>Option</Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredTenants.map((tenant) => (
                                            <TableRow key={tenant.tenant_code}>
                                                <TableCell> <Typography style={{ fontWeight: 'bold' }}>
                                                    {tenant.tenant_code}
                                                </Typography></TableCell>
                                                <TableCell> <Typography style={{ fontWeight: 'bold' }}>{tenant.tenant_name} </Typography></TableCell>
                                                <TableCell> <Typography style={{ fontWeight: 'bold' }}>{tenant.tenant_company_name} </Typography></TableCell>
                                                <TableCell>
                                                    <Tooltip
                                                        title={loading ? 'Loading...' : formatDataForTooltip(hoverData)}
                                                        arrow
                                                        placement="top"
                                                        onOpen={() => handleTenantHover(tenant.tenant_code)}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => handleTenantClick(tenant.tenant_code)}
                                                        >
                                                            View Asset
                                                        </Button>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell><Typography style={{ fontWeight: 'bold' }}>{tenant.Count}</Typography></TableCell>
                                                <TableCell><Typography style={{ fontWeight: 'bold' }}>{tenant.Active}</Typography></TableCell>
                                                <TableCell><Typography style={{ fontWeight: 'bold' }}>{tenant.Inactive}</Typography></TableCell>
                                                <TableCell>
                                                    <IconButton onClick={(event) => handleClick(event, tenant.tenant_code)}>
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={Boolean(anchorEl)}
                                                        onClose={handleMenuClose}
                                                    >
                                                        <MenuItem onClick={() => updateTenantStatus(selectedTenantCode, 'active')}> <Typography style={{ fontWeight: 'bold' }}>Active</Typography></MenuItem>
                                                        <MenuItem onClick={() => updateTenantStatus(selectedTenantCode, 'inactive')}><Typography style={{ fontWeight: 'bold' }}>Inactive</Typography></MenuItem>
                                                        <MenuItem onClick={() => handlelicenseOpen(selectedTenantCode)}><Typography style={{ fontWeight: 'bold' }}> Generate License </Typography></MenuItem>
                                                    </Menu>
                                                </TableCell>

                                                <Modal
                                                    open={modalVisible}
                                                    onClose={handleCloseModal}
                                                    aria-labelledby="modal-title"
                                                    aria-describedby="modal-description"
                                                >
                                                    <Box
                                                        sx={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            width: 400,
                                                            bgcolor: 'white',
                                                            border: '2px solid #000',
                                                            boxShadow: 24,
                                                            p: 4,
                                                            borderRadius: 2
                                                        }}
                                                    >
                                                        <Typography id="modal-title" variant="h6" component="h2">
                                                            Generate License
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: 2,
                                                                mt: 2,
                                                            }}
                                                        >
                                                            <DatePicker
                                                                selected={selectedDate}
                                                                onChange={handleDateChange}
                                                                dateFormat="dd/MM/yyyy"
                                                            />
                                                            <Button variant="contained" color="primary" onClick={handleGenerateLicense}>
                                                                Generate License
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Modal>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableWrapper>

                            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" sx={{
                                '& .MuiDialog-paper': {
                                    width: '80%', // Adjust this percentage or use a fixed value like '600px'
                                    maxWidth: 'none', // Remove max-width restriction if needed
                                },
                            }}>
                                <DialogTitle>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="h6">
                                            <strong>Systems Details</strong>
                                        </Typography>
                                    </Box>
                                </DialogTitle>
                                <DialogContent>
                                    {selectedTenant && (
                                        <div>
                                            <Typography variant="h5" gutterBottom>{selectedTenant.Name}</Typography>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                                position: 'sticky',
                                                top: 0,
                                                zIndex: 1,
                                                backgroundColor: '#fff',
                                                padding: '10px 0'
                                            }}>
                                                <input
                                                    type="text"
                                                    placeholder="Search by MAC Address $ AssetID"
                                                    value={searchTerm}
                                                    onChange={handleSearchChange}
                                                    style={{
                                                        height: '20px',
                                                        width: '400px',
                                                        fontSize: '18px',
                                                        padding: '10px',
                                                        marginRight: 'auto' // Space between search box and buttons
                                                    }}
                                                />
                                                <Button onClick={handleActionClick} variant="contained" style={{ marginRight: '10px' }}>Action</Button>
                                                <Button onClick={handleClose} variant="contained" color="secondary">Close</Button>
                                            </Box>
                                            <TableContainer component={Paper}>
                                                <div>
                                                    {/* <Button onClick={handleClose} style={{ float: 'right', marginLeft: 10 }} variant="contained" color="secondary">Close</Button>
                                                    <Button onClick={handleActionClick} style={{ float: 'right' }} variant="contained">Action</Button> */}
                                                    <div>
                                                        {/* <input
                                                            type="text"
                                                            placeholder="Search by MAC Address $ AssetID"
                                                            value={searchTerm}
                                                            onChange={handleSearchChange}
                                                            style={{
                                                                height: '20px',
                                                                width: '400px',
                                                                fontSize: '18px',
                                                                padding: '10px'
                                                            }}
                                                        /> */}

                                                        <Table style={{ backgroundColor: '#E6F7FF' }}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <Checkbox
                                                                            checked={selectAll}
                                                                            onChange={handleSelectAllChange}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell><Typography style={{ fontWeight: 'bold' }}>Asset Id</Typography></TableCell>
                                                                    <TableCell><Typography style={{ fontWeight: 'bold' }}>config</Typography></TableCell>
                                                                    <TableCell><Typography style={{ fontWeight: 'bold' }}>Active Status</Typography></TableCell>
                                                                    <TableCell><Typography style={{ fontWeight: 'bold' }}>Assigned To</Typography></TableCell>
                                                                    <TableCell><Typography style={{ fontWeight: 'bold' }}>MAC Address</Typography></TableCell>
                                                                    <TableCell><Typography style={{ fontWeight: 'bold' }}>Options</Typography></TableCell>
                                                                    <TableCell><Typography style={{ fontWeight: 'bold' }}>Add Comment</Typography></TableCell>
                                                                    <TableCell><Typography style={{ fontWeight: 'bold' }}>Logs</Typography></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {filteredSystems && filteredSystems.map((system, asset) => {
                                                                    console.log('System Object:', system);
                                                                    const macAddress = system.systemInfo?.Network?.MACAddress || 'N/A';
                                                                    console.log('MAC Address:', macAddress);
                                                                    return (
                                                                        <TableRow key={system.asset_id}>
                                                                            <TableCell>
                                                                                <Checkbox
                                                                                    checked={selectedSystems.includes(system.asset_id)}
                                                                                    onChange={(event) => handleCheckboxChange(event, system.asset_id)}
                                                                                    color="primary"
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>{system.asset_id}</TableCell>
                                                                            <TableCell>{system.name}</TableCell>
                                                                            <TableCell>{getActiveStatus(system.status)}</TableCell>

                                                                            <TableCell>{system.tenant_code}</TableCell>
                                                                            <TableCell>{macAddress}</TableCell>
                                                                            <TableCell>
                                                                                <IconButton onClick={(event) => handleMenuClick(event, system)}>
                                                                                    <MoreVertIcon />
                                                                                </IconButton>
                                                                                <Menu
                                                                                    anchorEl={anchorEl}
                                                                                    open={Boolean(anchorEl) && currentSystem === system}
                                                                                    onClose={handleMenuClose}
                                                                                >
                                                                                    <MenuItem onClick={() => handleViewConfiguration(system)}>View Configuration</MenuItem>
                                                                                </Menu>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <IconButton onClick={() => handleOpenModal(system)}>
                                                                                    <CommentIcon />
                                                                                </IconButton>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Tooltip
                                                                                    title={
                                                                                        <Typography style={{ fontSize: '14px', fontWeight: 'bold', color: '#ffffff' }}>
                                                                                            {`Configuration: ${system.name || "No Name Available"}`}
                                                                                        </Typography>
                                                                                    }
                                                                                    arrow // Adds an arrow to the tooltip for better visual indication
                                                                                    placement="top" // Optional: Set the tooltip placement (top, bottom, etc.)
                                                                                >
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        onClick={() => handleAssetClick(system.asset_id)}
                                                                                    >
                                                                                        View Logs
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                        <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md" PaperProps={{ style: { backgroundColor: '#E6F7FF' } }}>
                                                            <DialogTitle>
                                                                Audit Logs for Asset ID: {selectedAssetId}
                                                                <IconButton
                                                                    edge="end"
                                                                    color="inherit"
                                                                    onClick={handleCloseDialog}
                                                                    aria-label="close"
                                                                    style={{ position: 'absolute', right: 8, top: 8 }}
                                                                >
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </DialogTitle>
                                                            <DialogContent>
                                                                <FormControl fullWidth>
                                                                    <Select
                                                                        value={filter}
                                                                        onChange={handleFilterChange}
                                                                        style={{ marginBottom: '20px' }}
                                                                    >
                                                                        <MenuItem value="All">All</MenuItem>
                                                                        <MenuItem value="IPAddress change">IPAddress change</MenuItem>
                                                                        <MenuItem value="Assign tenant">Assign tenant</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                                <TableContainer component={Paper} style={{ maxHeight: '400px', backgroundColor: '#E6F7FF' }}>
                                                                    <Table stickyHeader>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell style={{ position: 'sticky', top: 0, backgroundColor: '#E6F7FF', zIndex: 1 }}>
                                                                                    <strong>Timestamp</strong>
                                                                                </TableCell>
                                                                                <TableCell style={{ position: 'sticky', top: 0, backgroundColor: '#E6F7FF', zIndex: 1 }}>
                                                                                    <strong>Action</strong>
                                                                                </TableCell>
                                                                                <TableCell style={{ position: 'sticky', top: 0, backgroundColor: '#E6F7FF', zIndex: 1 }}>
                                                                                    <strong>Message</strong>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {filteredLogs.map((log, index) => (
                                                                                <TableRow key={index}>
                                                                                    <TableCell>{log.timestamp}</TableCell>
                                                                                    <TableCell>{log.action}</TableCell>
                                                                                    <TableCell>{log.message}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </DialogContent>
                                                        </Dialog>
                                                    </div>

                                                    <Modal open={openModal} onClose={handleCloseModal}>
                                                        <div style={modalStyle}>
                                                            <h2>Comments for Asset: {currentSystem?.asset_id}</h2>
                                                            <div style={commentsContainerStyle}>
                                                                {comments.map((comment, index) => (
                                                                    <div key={index} style={commentStyle}>
                                                                        <p>{comment.comment}</p>
                                                                        <small style={{ color: "gray" }}>
                                                                            {new Date(comment.timestamp).toLocaleString()}
                                                                        </small>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <TextField
                                                                label="Add a Comment"
                                                                value={newComment}
                                                                onChange={(e) => setNewComment(e.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rows={4}
                                                                variant="outlined"
                                                                margin="normal"
                                                            />
                                                            <Button variant="contained" color="primary" onClick={handleAddComment}>
                                                                Save Comment
                                                            </Button>
                                                        </div>
                                                    </Modal>

                                                    <Dialog open={actionDialogOpen} onClose={handleActionClose}>
                                                        <DialogTitle><strong>Choose an action</strong></DialogTitle>
                                                        <DialogContent>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                <Button variant="contained" onClick={() => handleActionSubmit('shutdown')} style={{ marginRight: 10 }}>Shutdown</Button>
                                                                <Button variant="contained" onClick={() => handleActionSubmit('switchOn')} style={{ marginRight: 10 }}>Switch On</Button>
                                                                <Button variant="contained" onClick={() => handleActionSubmit('assign')} style={{ marginRight: 10 }}>Assign</Button>
                                                                <Button variant="contained" onClick={() => handleActionSubmit('updateStatus')}>Update Status</Button>
                                                            </div>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={handleActionClose} variant="contained" color="secondary">Close</Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </div>
                                            </TableContainer>
                                        </div>
                                    )}
                                </DialogContent>
                            </Dialog>
                            <Dialog open={assignDialogOpen} onClose={() => setDropdownOpen(false)}>
                                <DialogTitle><strong>Select Tenant to Assign</strong></DialogTitle>
                                <DialogContent>
                                    <div style={{ margin: 20 }}>   </div>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>Tenant Name</InputLabel>
                                        <Select
                                            value={selectedDropdownTenant}
                                            onChange={handleDropdownChange}
                                            label="Tenant Name"
                                        >
                                            {tenants.map((tenant) => (
                                                <MenuItem key={tenant.tenant_code} value={tenant.tenant_code}>
                                                    {tenant.tenant_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => handleAssign(selectedDropdownTenant)} variant="contained">Assign</Button>
                                    <Button onClick={() => setAssignDialogOpen(false)}>Cancel</Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={updateStatusDialogOpen} onClose={() => setUpdateStatusDialogOpen(false)}>
                                <DialogTitle><strong>Select Status to Update</strong></DialogTitle>
                                <DialogContent>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel style={{ marginTop: 5 }}>Status Name</InputLabel>
                                        <Select
                                            value={selectedDropdownTenant}
                                            onChange={handleDropdownChange}
                                            label="Status Name"
                                        >
                                            {['active', 'inactive'].map((status) => (
                                                <MenuItem key={status} value={status}>
                                                    {status}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => handleUpdateStatus(selectedDropdownTenant, currentTenantCode)} variant="contained">Update Status</Button>
                                    <Button onClick={() => setUpdateStatusDialogOpen(false)}>Cancel</Button>
                                </DialogActions>
                            </Dialog>
                            <Dialog open={systemConfigOpen} onClose={handleConfigDialogClose} fullScreen>
                                <DialogTitle><strong>System Information</strong></DialogTitle>
                                <DialogContent>
                                    <TableContainer component={Paper} style={{ maxHeight: 800 }}>
                                        <Table size="small" aria-label="system configuration table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ background: "#64b5f6" }}><strong>Property</strong></TableCell>
                                                    <TableCell sx={{ background: "#64b5f6" }}><strong>Old Value</strong></TableCell>
                                                    <TableCell sx={{ background: "#64b5f6" }}><strong>New Value</strong></TableCell>
                                                    <TableCell sx={{ background: "#64b5f6" }}><strong>Changes</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {allKeys.map((key, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            {key.includes('BIOS') && <DeveloperBoardIcon style={{ color: 'cyan' }} />}
                                                            {key.includes('BaseBoard') && <DesktopMacIcon style={{ color: 'orange' }} />}
                                                            {key.includes('OS') && <ComputerIcon style={{ color: 'blue' }} />}
                                                            {key.includes('RAM') && <MemoryIcon style={{ color: 'green' }} />}
                                                            {key.includes('ROM') && <SdCardIcon style={{ color: 'purple' }} />}
                                                            {key.includes('Network') && <NetworkCheckIcon style={{ color: 'red' }} />}
                                                            {key.includes('PHYSICALDRIVE') && <StorageIcon style={{ color: 'teal' }} />}
                                                            {key.includes('Processor') && <DevicesIcon style={{ color: 'lime' }} />}
                                                            <span style={{ marginLeft: '10px' }}>{key}</span>
                                                        </TableCell>
                                                        <TableCell>{getValue(oldData, key)}</TableCell>
                                                        <TableCell>{getValue(newData, key)}</TableCell>
                                                        <TableCell style={{ color: 'red' }}>{getValue(changes, key)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => handleAcceptChanges(asset_id)} variant="contained" color="primary">Accept Changes</Button>
                                    <Button onClick={() => setSystemConfigOpen(false)} variant="contained" color="secondary">Close</Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    )}
                    {tabIndex === 1 && (
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                <FormControl fullWidth variant="outlined" size="small" sx={{ marginRight: 2 }}>
                                    <Select
                                        value={systemStatus}
                                        onChange={handleSystemStatusChange}
                                    >
                                        <MenuItem value="active"><Typography style={{ fontWeight: 'bold' }}>Active System</Typography></MenuItem>
                                        <MenuItem value="inactive"><Typography style={{ fontWeight: 'bold' }}>Inactive System</Typography></MenuItem>
                                        <MenuItem value="shutdown"><Typography style={{ fontWeight: 'bold' }}>Shutdown System</Typography></MenuItem>
                                        <MenuItem value="changed"><Typography style={{ fontWeight: 'bold' }}>Changed System</Typography></MenuItem>
                                    </Select>
                                </FormControl>

                                <TextField
                                    size="small"
                                    label="Search Assets"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={systemSearchQuery}
                                    onChange={handleSystemSearch}
                                />
                            </div>

                            <TableContainer component={Paper} style={{ backgroundColor: '#E6F7FF', color: '#666', fontWeight: 'bold' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><Typography style={{ fontWeight: 'bold' }}>Asset ID</Typography></TableCell>
                                            <TableCell><Typography style={{ fontWeight: 'bold' }}>Tenant Code</Typography></TableCell>
                                            <TableCell><Typography style={{ fontWeight: 'bold' }}>Tenant Name</Typography></TableCell>
                                            <TableCell><Typography style={{ fontWeight: 'bold' }}>Action</Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredSystems.map((system) => (
                                            <TableRow key={system.asset_id}>
                                                <TableCell><Typography style={{ fontWeight: 'bold' }}>{system.asset_id}</Typography></TableCell>
                                                <TableCell><Typography style={{ fontWeight: 'bold' }}>{system.tenant_code}</Typography></TableCell>
                                                <TableCell><Typography style={{ fontWeight: 'bold' }}>{system.tenant_name}</Typography></TableCell>
                                                <TableCell>
                                                    <Button variant="contained" color="primary" onClick={() => handleViewConfiguration(system)}>
                                                        View Configuration
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Typography style={{ backgroundColor: '#E6F7FF', color: '#666', fontWeight: 'bold' }}>
                                <div style={{ position: 'sticky', bottom: 0, right: 0, margin: '20px', backgroundColor: '#E6F7FF', color: '#666', fontWeight: 'bold', padding: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="contained" color="primary" onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</Button>
                                    <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
                                    <Button variant="contained" color="primary" onClick={handleNextPage} disabled={currentPage === totalPages}>Next</Button>
                                </div>
                            </Typography>
                            <Dialog open={systemConfigOpen} onClose={handleConfigDialogClose} fullScreen>
                                <DialogTitle><strong>System Information</strong></DialogTitle>
                                <DialogContent>
                                    <TableContainer component={Paper} style={{ maxHeight: 800 }}>
                                        <Table size="small" aria-label="system configuration table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ background: "#64b5f6" }}><strong>Property</strong></TableCell>
                                                    <TableCell sx={{ background: "#64b5f6" }}><strong>Old Value</strong></TableCell>
                                                    <TableCell sx={{ background: "#64b5f6" }}><strong>New Value</strong></TableCell>
                                                    <TableCell sx={{ background: "#64b5f6" }}><strong>Changes</strong></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {allKeys.map((key, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            {key.includes('BIOS') && <DeveloperBoardIcon style={{ color: 'cyan' }} />}
                                                            {key.includes('BaseBoard') && <DesktopMacIcon style={{ color: 'orange' }} />}
                                                            {key.includes('OS') && <ComputerIcon style={{ color: 'blue' }} />}
                                                            {key.includes('RAM') && <MemoryIcon style={{ color: 'green' }} />}
                                                            {key.includes('ROM') && <SdCardIcon style={{ color: 'purple' }} />}
                                                            {key.includes('Network') && <NetworkCheckIcon style={{ color: 'red' }} />}
                                                            {key.includes('PHYSICALDRIVE') && <StorageIcon style={{ color: 'teal' }} />}
                                                            {key.includes('Processor') && <DevicesIcon style={{ color: 'lime' }} />}
                                                            <span style={{ marginLeft: '10px' }}>{key}</span>
                                                        </TableCell>
                                                        <TableCell>{getValue(oldData, key)}</TableCell>
                                                        <TableCell>{getValue(newData, key)}</TableCell>
                                                        <TableCell style={{ color: 'red' }}>{getValue(changes, key)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => handleAcceptChanges(asset_id)} variant="contained" color="primary">Accept Changes</Button>
                                    <Button onClick={() => setSystemConfigOpen(false)} variant="contained" color="secondary">Close</Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    )}
                </Grid>
            </RootContainer>
        </>
    );
};
export default DataTable1;


