import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Paper, Box, InputAdornment, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CircularProgress } from '@mui/material';

// Styled Paper component for a better look
const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: theme.shadows[5],
}));

const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoggingIn(true); // Start loading spinner

        // Simulate a delay for 2 seconds
        setTimeout(async () => {
            try {
                const response = await fetch('https://varnetbuddy.varnetsolutions.in/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ username, password }),
                });
                const data = await response.json();
                if (response.ok) {
                    toast.success("Logged In ~");
                    sessionStorage.setItem("token", data.token);
                    navigate('/customer');
                } else {
                    toast.error("Invalid Credentials ~");
                    console.error('Login failed:', data.message);
                }
            } catch (error) {
                toast.error("Error");
                console.error('Error logging in:', error);
            } finally {
                setIsLoggingIn(false); // Stop loading spinner
            }
        }, 2000); // 2-second delay
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Container
            component="main"
            fullWidth
            sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#11ffee00',
            }}
        >
            <ToastContainer />
            <StyledPaper elevation={3}>
                <Typography
                    variant="h4"
                    align="center"
                    gutterBottom
                    sx={{
                        fontWeight: 'bold',
                        color: '#1e90ff',
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                        letterSpacing: '1.5px',
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    }}
                >
                    Varnet System Buddy
                </Typography>

                <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        InputLabelProps={{ style: { color: '#000' } }}
                        InputProps={{ style: { color: '#000' } }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#000' }
                            }
                        }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputLabelProps={{ style: { color: '#000' } }}
                        InputProps={{
                            style: { color: '#000' },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { borderColor: '#000' }
                            }
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isLoggingIn}
                        sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: '#000',
                            color: '#fff',
                            '&:hover': { backgroundColor: '#333' },
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {isLoggingIn ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            'Sign In'
                        )}
                    </Button>

                </Box>
            </StyledPaper>
        </Container>
    );
};

export default LoginPage;
