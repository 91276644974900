import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography, Grid, Paper } from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const AgentModal = () => {
    const [open, setOpen] = useState(false);
    const [agents, setAgents] = useState([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        // Fetching the agent data from API
        axios.get('https://varnetbuddy.varnetsolutions.in/show_agent')
            .then(response => {
                setAgents(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching agents', error);
            });
    }, []);

    const toggleAgentStatus = (agent) => {
        const newStatus = agent.status === "enable" ? "disable" : "enable";

        axios.put(`https://varnetbuddy.varnetsolutions.in/update_status/${agent.agent_id}`, { status: newStatus })
            .then(response => {
                // Update the local state to reflect the new status
                setAgents(prevAgents =>
                    prevAgents.map(a =>
                        a.agent_id === agent.agent_id ? { ...a, status: newStatus } : a
                    )
                );
            })
            .catch(error => {
                console.error('Error updating agent status', error);
            });
    };


    return (
        <>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                Show Agents
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="agent-modal-title"
                aria-describedby="agent-modal-description"
            >
                <Box sx={modalStyle}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="agent-modal-title" variant="h6" component="h2">
                            List of Agents
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box sx={scrollContainerStyle}>
                        <Grid container spacing={2}>
                            {agents.map((agent, index) => (
                                <Grid item xs={12} md={6} key={index}>
                                    <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
                                        <Typography variant="subtitle1" color="textPrimary">
                                            <strong>Agent ID:</strong> {agent.agent_id}
                                        </Typography>
                                        <Typography variant="subtitle1" color="textPrimary">
                                            <strong>Name:</strong> {agent.name}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Email:</strong> {agent.email}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Mobile/Username:</strong> {agent.mobile_number}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Designation:</strong> {agent.designation}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Status:</strong> {agent.status}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Password:</strong> {agent.password}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color={agent.status === "enable" ? "secondary" : "primary"}
                                            onClick={() => toggleAgentStatus(agent)}
                                        >
                                            {agent.status === "enable" ? "Disable" : "Enable"}
                                        </Button>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const scrollContainerStyle = {
    maxHeight: '80vh',
    overflowY: 'auto',
};

export default AgentModal;
