import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, FormControl, InputLabel, Select, MenuItem, TableRow, Paper, Button, Typography, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TablePagination } from '@mui/material';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom'; 

const AuditLogs = () => {
    const [assets, setAssets] = useState([]);
    const [selectedAssetId, setSelectedAssetId] = useState(null);
    const [logs, setLogs] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [filter, setFilter] = useState('All');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState(''); 
    const navigate = useNavigate();


    const handleClosePage = () => {
        navigate('/customer');  
    };


    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredAssets = assets.filter(asset =>
        asset.asset_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        asset.IPAddress.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        axios.get('https://varnetbuddy.varnetsolutions.in/get_asset_ids')
            .then(response => {
                setAssets(response.data);
            })
            .catch(error => {
                console.error('Error fetching asset IDs:', error);
            });
    }, []);

    const handleAssetClick = (assetId) => {
        setSelectedAssetId(assetId);
        axios.get(`https://varnetbuddy.varnetsolutions.in/get_audit_logs/${assetId}`)
            .then(response => {
                const sortedLogs = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                setLogs(sortedLogs);
                setFilteredLogs(sortedLogs);
                setOpenDialog(true);
            })
            .catch(error => {
                console.error(`Error fetching logs for asset ${assetId}:`, error);
            });
    };

    const handleFilterChange = (event) => {
        const selectedFilter = event.target.value;
        setFilter(selectedFilter);

        if (selectedFilter === 'All') {
            setFilteredLogs(logs);
        } else {
            const filtered = logs.filter(log => log.action === selectedFilter);
            setFilteredLogs(filtered);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setLogs([]);
    };

    return (
        <div style={{ maxWidth: '1200px', backgroundColor: '#E6F7FF' }}>
            <Typography variant="h4" gutterBottom style={{ position: 'sticky', top: 0, backgroundColor: '#E6F7FF', zIndex: 1 }}>Asset IDs</Typography>
            <IconButton
                onClick={handleClosePage}
                style={{ position: 'absolute', right: 16, top: 16 }}
                aria-label="close"
                sx={{ color: '#333' }}
            >
                <CloseIcon />
            </IconButton>
            <TextField
                label="Search by Asset ID or IP Address"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ marginBottom: '20px' }}
            />

            <TableContainer component={Paper} style={{ marginBottom: '20px', backgroundColor: '#E6F7FF' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Asset ID</strong></TableCell>
                            <TableCell><strong>Actions</strong></TableCell>
                            <TableCell><strong>Tenant Name</strong></TableCell>
                            <TableCell><strong>Current IP Address</strong></TableCell>
                            <TableCell><strong>Timestamp</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAssets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((asset, index) => (
                            <TableRow key={index}>
                                <TableCell>{asset.asset_id}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleAssetClick(asset.asset_id)}
                                    >
                                        View Logs
                                    </Button>
                                </TableCell>
                                <TableCell>{asset.tenant_name}</TableCell>
                                <TableCell>{asset.IPAddress}</TableCell>
                                <TableCell>{asset.timestamp}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={assets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md" PaperProps={{ style: { backgroundColor: '#E6F7FF' } }}>
                <DialogTitle>
                    Audit Logs for Asset ID: {selectedAssetId}
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseDialog}
                        aria-label="close"
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                    {/* <InputLabel variant="outline" sx={{ borderColor: 'primary.main', borderWidth: 2 }} style={{ marginTop: "10px", marginLeft:"5px" }}>Filter Logs</InputLabel> */}
                        <Select
                            value={filter}
                            onChange={handleFilterChange}
                            style={{ marginBottom: '20px' }}
                        >
                            <MenuItem value=""></MenuItem>
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="IPAddress change">IPAddress change</MenuItem>
                            <MenuItem value="Assign tenant">Assign tenant</MenuItem>
                        </Select>
                    </FormControl>
                    <TableContainer component={Paper} style={{ maxHeight: '400px', backgroundColor: '#E6F7FF' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ position: 'sticky', top: 0, backgroundColor: '#E6F7FF', zIndex: 1 }}>
                                        <strong>Timestamp</strong>
                                    </TableCell>
                                    <TableCell style={{ position: 'sticky', top: 0, backgroundColor: '#E6F7FF', zIndex: 1 }}>
                                        <strong>Action</strong>
                                    </TableCell>
                                    <TableCell style={{ position: 'sticky', top: 0, backgroundColor: '#E6F7FF', zIndex: 1 }}>
                                        <strong>Message</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredLogs.map((log, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{log.timestamp}</TableCell>
                                        <TableCell>{log.action}</TableCell>
                                        <TableCell>{log.message}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>

            </Dialog>
        </div>
    );
};

export default AuditLogs;
