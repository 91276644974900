import React from 'react';
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import './App.css';
import DataTable from './components/customer';
import LoginPage from './components/login';
import Overview from './components/overview';
import Layout from './Layout';
import AuditLogs from './components/AuditLogs';
import RequestModal from './components/RequestModal';
// import RequestModal from './components/RequestModal';
// import RequestModal from './components/RequestModal';


function App() {
  return (
    <Router>
      <Routes >
        <Route path="/" element={<LoginPage />}/>
        <Route path="/customer" element={< DataTable />} />
          <Route path="/overview" element={< Overview />} />
          <Route path="/login"    element={<LoginPage />} />
          <Route path="/audit-logs" element={<AuditLogs />} />
          <Route path="/request-modal" element={<RequestModal />} />
          
    
      </Routes>
    </Router>
  );
}

export default App;
