// import React, { useState, useEffect } from 'react';
// import { Button, Modal, Typography, Box, CircularProgress, List, ListItem, ListItemText } from '@mui/material';
// import axios from 'axios';

// const modalStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: '80%', // Adjust width as needed
//   maxWidth: 600,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
// };

// const contentStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   overflowY: 'auto', // Enable vertical scrolling
//   maxHeight: '70vh', // Adjust height as needed
//   width: '100%',
// };

// const RequestModal = ({ open, onClose }) => {
//   const [requests, setRequests] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [selectedRequest, setSelectedRequest] = useState(null);

//   useEffect(() => {
//     if (open) {
//       fetchRequests();
//     }
//   }, [open]);

//   const fetchRequests = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.get('https://varnetbuddy.varnetsolutions.in/viewLastPendingRequests');
//       setRequests(response.data.pending_requests);
//     } catch (error) {
//       console.error('Failed to fetch request data', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleApproveRequest = async (asset_id) => {
//     if (!asset_id) return;
//     try {
//       await axios.post(`https://varnetbuddy.varnetsolutions.in/approveRequest/${asset_id}`, {
//         action: 'approve'
//       });
//       setRequests(requests.filter(request => request.asset_id !== asset_id));
//       setSelectedRequest(null);
//     } catch (error) {
//       console.error('Failed to approve request', error);
//     }
//   };

//   const handleRejectRequest = async (asset_id) => {
//     if (!asset_id) return;
//     try {
//       await axios.post(`https://varnetbuddy.varnetsolutions.in/approveRequest/${asset_id}`, {
//         action: 'reject'
//       });
//       setRequests(requests.filter(request => request.asset_id !== asset_id));
//       setSelectedRequest(null);
//     } catch (error) {
//       console.error('Failed to reject request', error);
//     }
//   };

//   return (
//     <Modal open={open} onClose={onClose}>
//       <Box sx={modalStyle}>
//         {loading ? (
//           <CircularProgress />
//         ) : (
//           <Box sx={contentStyle}>
//             <List>
//               {requests.map((request) => (
//                 <ListItem
//                   button
//                   key={request.asset_id}
//                   onClick={() => setSelectedRequest(request)}
//                   sx={{ position: 'relative', mb: 1, display: 'flex', alignItems: 'flex-start' }}
//                 >
//                   <Box sx={{ flex: 1 }}>
//                     <ListItemText
//                       primary={request.message}
//                       secondary={`Status: ${request.status}`}
//                       primaryTypographyProps={{ color: request.status === 'pending' ? 'red' : 'green' }}
//                     />
//                   </Box>
//                   {selectedRequest && selectedRequest.asset_id === request.asset_id && (
//                     <Box
//                       sx={{
//                         display: 'flex',
//                         flexDirection: 'column',
//                         alignItems: 'center',
//                         ml: 2, // Add margin-left for spacing between text and buttons
//                       }}
//                     >
//                       <Button
//                         variant="contained"
//                         color="success"
//                         onClick={() => handleApproveRequest(request.asset_id)}
//                         sx={{ mb: 1, whiteSpace: 'nowrap' }} // Ensure button text does not wrap
//                       >
//                         Approve
//                       </Button>
//                       <Button
//                         variant="contained"
//                         color="error"
//                         onClick={() => handleRejectRequest(request.asset_id)}
//                         sx={{ whiteSpace: 'nowrap' }} // Ensure button text does not wrap
//                       >
//                         Reject
//                       </Button>
//                     </Box>
//                   )}
//                 </ListItem>
//               ))}
//             </List>
//           </Box>
//         )}
//       </Box>
//     </Modal>
//   );
// };

// export default RequestModal;

import React, { useState, useEffect } from 'react';
import { Button, Modal, Typography, Box, CircularProgress, List, ListItem, ListItemText } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for toastify

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%', // Adjust width as needed
  maxWidth: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflowY: 'auto', // Enable vertical scrolling
  maxHeight: '70vh', // Adjust height as needed
  width: '100%',
};

const RequestModal = ({ open, onClose }) => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  useEffect(() => {
    if (open) {
      fetchRequests();
    }
  }, [open]);

  const fetchRequests = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://varnetbuddy.varnetsolutions.in/viewLastPendingRequests');
      setRequests(response.data.pending_requests);
    } catch (error) {
      console.error('Failed to fetch request data', error);
    } finally {
      setLoading(false);
    }
  };

  const handleApproveRequest = async (asset_id) => {
    if (!asset_id) return;
    try {
      await axios.post(`https://varnetbuddy.varnetsolutions.in/approveRequest/${asset_id}`, {
        action: 'approve'
      });
      setRequests(requests.filter(request => request.asset_id !== asset_id));
      setSelectedRequest(null);
      toast.success('Request approved successfully!');
    } catch (error) {
      console.error('Failed to approve request', error);
      toast.error('Failed to approve request.');
    }
  };
  
  const handleRejectRequest = async (asset_id) => {
    if (!asset_id) return;
    try {
      await axios.post(`https://varnetbuddy.varnetsolutions.in/approveRequest/${asset_id}`, {
        action: 'reject'
      });
      setRequests(requests.filter(request => request.asset_id !== asset_id));
      setSelectedRequest(null);
      toast.success('Request rejected successfully!');
    } catch (error) {
      console.error('Failed to reject request', error);
      toast.error('Failed to reject request.');
    }
  };
  
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box sx={contentStyle}>
            <List>
              {requests.map((request) => (
                <ListItem
                  button
                  key={request.asset_id}
                  onClick={() => setSelectedRequest(request)}
                  sx={{ position: 'relative', mb: 1, display: 'flex', alignItems: 'flex-start' }}
                >
                  <Box sx={{ flex: 1 }}>
                    <ListItemText
                      primary={request.message}
                      secondary={`Status: ${request.status}`}
                      primaryTypographyProps={{ color: request.status === 'pending' ? 'red' : 'green' }}
                    />
                  </Box>
                  {selectedRequest && selectedRequest.asset_id === request.asset_id && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        ml: 2, // Add margin-left for spacing between text and buttons
                      }}
                    >
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleApproveRequest(request.asset_id)}
                        sx={{ mb: 1, whiteSpace: 'nowrap' }} // Ensure button text does not wrap
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleRejectRequest(request.asset_id)}
                        sx={{ whiteSpace: 'nowrap' }} // Ensure button text does not wrap
                      >
                        Reject
                      </Button>
                    </Box>
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        <ToastContainer />
      </Box>
    </Modal>
  );
  
};

export default RequestModal;
